import React from 'react';
import { Link } from 'gatsby';
import { Document, Page } from 'react-pdf/dist/entry.webpack';


import { AuthUserContext } from '../../components/Session';


import FavoriteForm from '../../components/Favorite';
//import { Router } from '@reach/router';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faDownload } from '@fortawesome/pro-light-svg-icons';

//import * as ROUTES from '../../constants/routes'


class ManualList extends React.Component {
    constructor(props){
        super(props);
        this.data = Object.keys(props.array).map(i => props.array[i]);


        if(this.props.sort === "type"){
            this.data = this.data.sort(this.compareType)
        }

        if(this.props.sort === "Manufacturer"){
           this.data = this.data.sort(this.compareCompany)
        }


        this.state = {
            array: this.data,
            numPages: null,
            pageNumber: 1,
        };
    };


    onClick = event => {
        event.preventDefault();
    }

    compareCompany(a, b){
        const typeA = a.companyName.toUpperCase();
        const typeB = b.companyName.toUpperCase();

        let comparison = 0

        if (typeA > typeB){
            comparison = 1;
        }

        else if ( typeA < typeB ){
            comparison = -1;
        }

        return comparison;
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.array!==prevState.array){
            return { array: nextProps.array};
         }
         else return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.array!==this.props.array){
          //Perform some operation here
          this.setState({array: this.props.array});
        }
      }


    render(){


        console.log(this.state.array);
        return(
            <div>
                {this.state.array.length === 0 ?
                    <div id="no-results">Sorry, there are no manuals matching that description. &nbsp;<button class="button small ghost" onClick={this.props.onClearManualList}>Clear Filter</button>
                </div> : <></> }
                <ul className="cards">
                {
                    this.state.array.map( ( query,i ) => {
                        const title = query.title;
                        const uri = query.uri;
                        const language = query.language[0].title;
                        const type = query.elevatorType[0].title;
                        const manufacturer = query.elevatorManufacturer[0].title;
                        const fileURL = query.manual.url;
                        console.log(fileURL);
                        var p = query.slug;
                        const pathname = p.replace('-', '_');
                    return (
                        <li key={query.title + i}>
                           <article className="card">
                                <header>
                                    <Link to={"/"+query.uri}><h2>{query.title}</h2></Link>
                                    <AuthUserContext.Consumer>
                                      {authUser =>
                                        authUser ? (
                                            <><FavoriteForm pathname={pathname} slug={p} title={title} uri={uri} type={type} manufacturer={manufacturer} language={language} fileURL={fileURL} checkMe={authUser.favorites.hasOwnProperty(pathname) ? true : false } /></>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    </AuthUserContext.Consumer>

                                </header>
                                <ul>
                                <Link to={"/"+query.uri}>
                                    <li className="pdfThumb">
                                        <div class="thumb-cover"></div>
                                            <embed src={fileURL+"#toolbar=0&navpanes=0&scrollbar=0&page=1&pagemode=thumb"} scrolling="no" style={{overflow:"hidden"}} width="370px" height="370px" />
                                    </li>
				                    <li>Manufacturer:{manufacturer} </li>
                                    <li>Tag: {type}</li>
                                    <li>Language: {language}</li>
                                </Link>
                                </ul>
                                <footer>
                                    <Link className="button ghost" to={"/"+query.uri}>View Manual</Link>
                                </footer>
                            </article>
                        </li>
                    )
                    })
                }
                </ul>
            </div>
        )
    }
}
export default ManualList;

/*
removed due to cors issues
                                        <Document file={{ url: fileURL, httpHeaders:{"Access-Control-Allow-Origin":"*","Access-Control-Allow-Headers": "*","Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS", "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"}}}>
                                            <Page  loading="lazy" pageNumber={1} scale={0.4}/>
                                        </Document>
*/